import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import AxiosMain from '../../utils/axios_main';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  boxes: [],
  box: null,
};

const slice = createSlice({
  name: 'boxdata',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ORGANIZATION CATEGORIES
    getBoxesSuccess(state, action) {
      state.isLoading = false;
      state.boxes = action.payload;
    },

    // GET ORGANIZATION CATEGORY
    getBoxSuccess(state, action) {
      state.isLoading = false;
      state.box = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getBoxesData(storeId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await AxiosMain.get(`/boxes/getBoxData/${storeId}`);
    //   console.log(response.data);
      dispatch(slice.actions.getBoxesSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBoxData(boxId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await AxiosMain.get(`/boxes/getBoxDataById/${boxId}`);
      dispatch(slice.actions.getBoxSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}