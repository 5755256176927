import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import AxiosMain from '../../utils/axios_main';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  userRoles: null,
};

const slice = createSlice({
  name: 'schemes',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ORGANIZATION CATEGORIES
    getUserRolesSuccess(state, action) {
      state.isLoading = false;
      state.userRoles = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
// } = slice.actions;

// ----------------------------------------------------------------------

export function getUserRoles(userID) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await AxiosMain.get(`/users/userRolesById/${userID}`);
      dispatch(slice.actions.getUserRolesSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}