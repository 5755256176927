import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import AxiosMain from '../../utils/axios_main';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  storeshortingproducts:[],
  storeshortingprodct:'',
};

const slice = createSlice({
  name: 'storeshortingproducts',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // // GET CATEGORIES
    // getOrganizationsSuccess(state, action) {
    //   state.isLoading = false;
    //   state.organizations = action.payload;
    // },
    // GET CATEGORY
    getstoreshortingproductsSuccess(state, action) {
      state.isLoading = false;
      state.storeshortingproducts = action.payload;
    },

    getstoreshortingproductdataSuccess(state, action) {
      state.isLoading = false;
      state.storeshortingprodct = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
// } = slice.actions;

// ----------------------------------------------------------------------

export function getStoreshortingProducts(storeId) {
  console.log(storeId);
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await AxiosMain.get(`/store_transfer/getAllStoreTransferList/${storeId}`);
      console.log(response.data);
      dispatch(slice.actions.getstoreshortingproductsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getStoreshortingBystoreshortingid(transferID) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await AxiosMain.get(`/store_transfer/getAllStoreTransferListByStoretransferID/${transferID}`);
      dispatch(slice.actions.getstoreshortingproductdataSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

