
import { useDispatch, useSelector } from '../../../redux/store';
import { getUserRoles } from '../../../redux/slices/userRoles';

export default function GetRoute(data){
    let returnData = false;
    const { userRoles } = useSelector((state) => state.userRoles);
    if(userRoles && userRoles.role_modules){
      const getData = userRoles.role_modules.filter((filterdata) => filterdata.module.module_name === data);
      if(getData.length > 0){
        returnData = true;
      }
    }
    return returnData;
}