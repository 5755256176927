import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice, getType } from '@reduxjs/toolkit';
import get from 'lodash/get';


// utils
import axios from '../../utils/axios';
import AxiosMain from '../../utils/axios_main';
//
import { dispatch } from '../store';


// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  products: [],
  product: null,
  productBatchDetails: [],
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
    totalItems: 0,
    existingScheme: false,
    scheme: null,
    order: null,
    user: null,
  },
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    getBatchDetailsBySkuIdStoreIDSuccess(state, action) {
      state.isLoading = false;
      state.productBatchDetails = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    // CHECKOUT
    getCart(state, action) {

      const checkout = action.payload;

      const totalItems = sum(checkout.cart.map((product) => product.quantity));
      const total = sum(checkout.cart.map((product) => product.price * product.quantity * product.sellingQunatity));
      const gst = sum(checkout.cart.map((product) => ((product.price * product.quantity * product.sellingQunatity) / 100) * (product.gst)));
      const subtotal = total - gst;
      let cartDiscount = 0;
      let SchemePrice = 0;
      if (checkout.scheme) {
        const schemedsc = parseFloat(checkout.scheme.SchemeDiscount);
        if (checkout.existingScheme === false) {
          SchemePrice = parseFloat(checkout.scheme.scheme_price);
        }
        cartDiscount = (total / 100) * (schemedsc);
        // console.log(cartDiscount, SchemePrice);        
      } else {        
        cartDiscount = sum(checkout.cart.map((product) => ((product.price * product.quantity * product.sellingQunatity) / 100) * (product.productDiscount)));
      }
      state.checkout.cart = checkout.cart;
      state.checkout.scheme = checkout.scheme;
      state.checkout.existingScheme = checkout.existingScheme;
      state.checkout.discount = (state.checkout.discount) || 0;
      state.checkout.shipping = state.checkout.shipping || 0;
      state.checkout.billing = state.checkout.billing || null;
      state.checkout.subtotal = subtotal.toFixed(2);
      state.checkout.storeId = state.checkout.user?.storeid || 0;
      state.checkout.organizationId = state.checkout.user?.organization_id || 0;
      state.checkout.hubId = state.checkout.user?.hubId || 0;
      state.checkout.createdBy = state.checkout.user?.id || 0;
      state.checkout.cartDiscount = cartDiscount.toFixed(2);
      state.checkout.gst = gst.toFixed(2);
      state.checkout.total = ((subtotal - state.checkout.discount - cartDiscount + gst + SchemePrice).toFixed(2));
      state.checkout.totalItems = totalItems;
    },

    addToCart(state, action) {
      const newProduct = action.payload;
      const isEmptyCart = !state.checkout.cart.length;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, newProduct];
      } else {
        state.checkout.cart = state.checkout.cart.map((product) => {
          const isExisted = product.id === newProduct.id;

          if (isExisted) {
            return {
              ...product,
              colors: uniq([...product.colors, ...newProduct.colors]),
              quantity: product.quantity + 1,
            };
          }

          return product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, newProduct], 'id');
      state.checkout.totalItems = sum(state.checkout.cart.map((product) => product.quantity));
    },

    deleteCart(state, action) {
      const updateCart = state.checkout.cart.filter((product) => product.id !== action.payload);

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
      state.checkout.existingScheme = false;
      state.checkout.scheme = null;
      state.checkout.order = null;
      state.checkout.user = null;
    },

    backStep(state) {
      state.checkout.activeStep -= 1;
    },

    nextStep(state) {
      state.checkout.activeStep += 1;
    },

    gotoStep(state, action) {
      const step = action.payload;
      state.checkout.activeStep = step;
    },

    addOrder(state, action) {
      const order = action.payload;
      state.checkout.order = order;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;

      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    addUser(state, action) {
      state.checkout.user = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
    },

    createScheme(state, action) {
      const scheme = action.payload;
      state.checkout.existingScheme = scheme.existingScheme;
      state.checkout.scheme = scheme.scheme;
      const schemedsc = parseFloat(scheme.scheme.SchemeDiscount);
      const updateCart = state.checkout.cart.map((product) => {
        return {
          ...product,
          discount: schemedsc,
        };
      });
      state.checkout.cart = updateCart;
    },

    removeScheme(state) {
      state.checkout.existingScheme = false;
      state.checkout.scheme = null;
      const updateCart = state.checkout.cart.map((product) => {
        return {
          ...product,
          discount: product.productDiscount,
        };
      });
      state.checkout.cart = updateCart;
    }

  },
});


// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addToCart,
  resetCart,
  gotoStep,
  backStep,
  nextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  increaseQuantity,
  decreaseQuantity,
  createScheme,
  removeScheme,
  addOrder,
  addUser
} = slice.actions;

// ----------------------------------------------------------------------

export function getProducts(storeId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await AxiosMain.get(`/medicines/getSkuAlldataBystoreId/${storeId}`);
      dispatch(slice.actions.getProductsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProduct(name) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {

      // const response = await dataForSingleProduct.forEach((element) => {

      //   if (get(element, name)) {
      //     dispatch(slice.actions.getProductSuccess(get(element, name)));
      //   }

      // });

    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getBatchDetailsBySkuIdStoreID(skuId, StoreID) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await AxiosMain.get(`/medicines/getBatchDetailsBySkuIdStoreId/${StoreID}/${skuId}`);
      dispatch(slice.actions.getBatchDetailsBySkuIdStoreIDSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
