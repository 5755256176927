import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import ordersReducer from './slices/orders';
import skurequestReducer from './slices/skurequest'
import boxdataReducer from './slices/boxdata';
import allProductsReducer from './slices/products';
import storeReducer from './slices/store';
import skusrequestcheckoutReducer from './slices/skusrequestcheckout';
import schemesReducer from './slices/schemes';
import storeShortingListReducer from './slices/store_shorting_list';
import userRolesReduser from './slices/userRoles';
import cancelSkuReduser from './slices/cancelSku';
import discountslabReduser from './slices/discountslab';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const productCancelPersistConfig = {
  key: 'cancelProduct',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  order:ordersReducer,
  skurequest:skurequestReducer,
  allproducts:allProductsReducer,
  boxdata:boxdataReducer,
  store:storeReducer,
  skurequestcheckout:skusrequestcheckoutReducer,
  schemes: schemesReducer,
  storeSortingList: storeShortingListReducer,
  userRoles: userRolesReduser,
  cancelProduct: persistReducer(productCancelPersistConfig ,cancelSkuReduser),
  discountSlabs: discountslabReduser,
});

export { rootPersistConfig, rootReducer };
