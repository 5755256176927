import axios from 'axios';
// config 
import { HOST_API_KEY_MAIN } from '../config';

// ----------------------------------------------------------------------

const AxiosMain = axios.create({
    // baseURL : "http://localhost:3021/",
    // baseURL : "https://caterhealthapi.cafemobility.com/",
    // baseURL : "https://api.zillionpos.com/",
    baseURL : "https://stagingapi.zillionpos.com/",
    headers: {
        Authorization: HOST_API_KEY_MAIN,
        "x-access-token": HOST_API_KEY_MAIN,
    }
});

AxiosMain.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default AxiosMain;
