import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback } from 'react';
// utils
import axios from '../utils/axios';
import AxiosMain from '../utils/axios_main';
//
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
    isInitialized: false,
    isAuthenticated: false,
    user: null,
};

const reducer = (state, action) => {
    if (action.type === 'INITIAL') {
        return {
            isInitialized: true,
            isAuthenticated: action.payload.isAuthenticated,
            user: action.payload.user,
        };
    }
    if (action.type === 'LOGIN') {
        return {
            ...state,
            isAuthenticated: true,
            user: action.payload.user,
        };
    }
    if (action.type === 'REGISTER') {
        return {
            ...state,
            isAuthenticated: true,
            user: action.payload.user,
        };
    }
    if (action.type === 'LOGOUT') {
        return {
            ...state,
            isAuthenticated: false,
            user: null,
        };
    }

    return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);
// ----------------------------------------------------------------------

AuthProvider.propTypes = {
    children: PropTypes.node,
};

export function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const initialize = useCallback(async () => {
        try {
            const accessToken = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : '';

            if (accessToken && isValidToken(accessToken)) {
                setSession(accessToken);

                const APiTokenData = {
                    api_token : accessToken
                }
                const response = await AxiosMain.post('/getuserbytoken', APiTokenData);
                // console.log(response.data);
                const { user } = response.data;

                dispatch({
                    type: 'INITIAL',
                    payload: {
                        isAuthenticated: true,
                        user,
                    },
                });
            } else {
                dispatch({
                    type: 'INITIAL',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        } catch (error) {
            console.error(error);
            dispatch({
                type: 'INITIAL',
                payload: {
                    isAuthenticated: false,
                    user: null,
                },
            });
        }
    }, []);

    useEffect(() => {
        initialize();
    }, [initialize]);

    // LOGIN
    const login = async (email, password) => {
        console.log(email);
        console.log(password);
      
        const response = await AxiosMain.get(`users/staff_login/${email}/${password}`);
          console.log(response.data);
        const { accessToken, user } = response.data;
        
        // const accessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI4ODY0YzcxNy01ODdkLTQ3MmEtOTI5YS04ZTVmMjk4MDI0ZGEtMCIsImlhdCI6MTY3MDQxMDM5MSwiZXhwIjoxNjcwNjY5NTkxfQ.bdlR6KvHTSrHyEyP0P4EwmUO2i-TlyhWSHn7FdPP204";
        // const user = {
        //     "id":"8864c717-587d-472a-929a-8e5f298024da-0",
        //     "displayName":"Devaraaya",
        //     "email":"zillionpos@caterwealth.com",
        //     "password":"demo1234",
        //     "photoURL":"https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_default.jpg",
        //     "phoneNumber":"+40 777666555",
        //     "country":"United States",
        //     "address":"90210 Broadway Blvd",
        //     "state":"California",
        //     "city":"San Francisco",
        //     "zipCode":"94116",
        //     "about":"Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.",
        //     "role":"admin",
        //     "isPublic":true
        // };
        setSession(accessToken);

        dispatch({
            type: 'LOGIN',
            payload: {
                user,
            },
        });
    };

    // REGISTER
    const register = async (email, password, firstName, lastName) => {
        const response = await axios.post('/api/account/register', {
            email,
            password,
            firstName,
            lastName,
        });
        const { accessToken, user } = response.data;

        localStorage.setItem('accessToken', accessToken);

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        });
    };

    // LOGOUT
    const logout = async () => {
        setSession(null);
        dispatch({
            type: 'LOGOUT',
        });
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: '',
                login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
