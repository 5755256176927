import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice, getType } from '@reduxjs/toolkit';
import get from 'lodash/get';
// utils
import AxiosMain from '../../utils/axios_main';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  storereq: [],
    storereqproducts: []
};

const slice = createSlice({
  name: 'sku request',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET REQUESTs
    getSkuReq(state, action) {
      state.isLoading = false;
      state.storereq = action.payload;
    },

      // GET REQUESTs
      getSkuReqProducts(state, action) {
          state.isLoading = false;
          state.storereqproducts = action.payload;
      },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getSkuReqListByStoreId(storeId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await AxiosMain.get(`/store_hub_requests/getSkuReqListByStoreId/${storeId}`);
      // console.log(response);
      dispatch(slice.actions.getSkuReq(response.data));
      // console.log(response);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSkuReqProducts(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await AxiosMain.get(`/store_hub_requests/requestProducts/${id}`);
            // console.log(response);
            dispatch(slice.actions.getSkuReqProducts(response.data));
            // console.log(response);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
